import { QueryPreviewInfo } from '@empathyco/x-components/types';

export const queriesPreviewFallback: QueryPreviewInfo[] = [
  {
    query: 'bold minimalism',
    title: 'BOLD MINIMALISM'
  },
  {
    query: 'anillos oro',
    title: 'ANILLOS ORO'
  },
  {
    query: 'pendientes oro',
    title: 'PENDIENTES ORO'
  },
  {
    query: 'pulseras oro',
    title: 'PULSERAS ORO'
  },
  {
    query: 'collares oro',
    title: 'COLLARES ORO'
  }
];
